<template>
  <div v-if="show" class="modal-detail">
    <vxe-modal v-model="show" :title="name" width="400" min-width="400" height="220" min-height="220" showFooter
      esc-closable mask-closable resize>
      <template v-slot>
        <el-form ref="form" size="small" :model="data" label-width="150px" label-position="top"
          @submit.native.prevent status-icon :rules="rules" :validate-on-rule-change="false">
          <el-form-item prop="username" label="用户名称">
            <el-input v-model.trim="data.username" maxlength="6" type="text" show-word-limit placeholder="请输入用户名称"/>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <div style="text-align: center">
          <vxe-button status="primary" style="width: 100%" @click="save" v-loading="loading">确定</vxe-button>
        </div>
      </template>
    </vxe-modal>
  </div>
</template>


<script>
export default {
  data() {
    return {
      loading: false,
      name: '',
      userId: null,
      data: {},
      rules: {
        username: { required: true, message: '请输入用户名称', trigger: 'blur' },
      },
      show: false,
    }
  },
  methods: {
    display(data) {
      this.show = true
      this.name = data.name
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
      this.data = data.data
      this.userId = data.userId
    },
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.$axios
            .get(`/auth/user/updateName/${this.data.username}`)
            .then(res => {
              if (res) {
                this.show = false
                this.$router.push({ name: '首页' })
                const userInfo = {
                  name: this.data.username,
                  userId: this.userId
                }
                localStorage.setItem('userInfo', JSON.stringify(userInfo))
              }
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style>
</style>