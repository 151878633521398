<template>
  <div class="login">

    <vue-particles color="#fff" :particleOpacity="0.7" :particlesNumber="60" shapeType="circle" :particleSize="4"
      linesColor="#fff" :linesWidth="1" :lineLinked="true" :lineOpacity="0.4" :linesDistance="150" :moveSpeed="2"
      :hoverEffect="true" hoverMode="grab" :clickEffect="true" clickMode="push" class="lizi">
    </vue-particles>
    <div class="login-container">
      <div class="login-center cf">
        <div class="fl">
          <img src="@/assets/ml_logo.png" alt="" class="logo-img">
        </div>
        <div class="fr login-form">
          <div class="title-container">
            <h1 class="title">过磅系统</h1>
          </div>
          <div class="page-login-qrcode">
            <div class="qrcode" id="qrcode" ref="qrCodeUrl">
              <div class="faceCode-shade" v-show="isTimeout">
                <p class="faceCode-title">二维码已失效</p>
                <a class="refresh" @click="refresh()">刷新</a>
              </div>
            </div>
          </div>
          <div class="page-login-footer-title">登录迈至科小程序，扫描二维码</div>
        </div>
      </div>
      <div class="login-footer">
        <span style="color: #fff">
          © 2021 安阳迈至科商贸有限公司<br/>
          <a href="https://beian.miit.gov.cn/" class="login-a" target="_blank">豫JCP备2021024175号-1</a>
        </span>
      </div>
    </div>
    
    <detail ref="detail" />
  </div>
</template>

<script>
import detail from './components/detail'
import QRCode from 'qrcodejs2'
export default {
  name: 'Login',
  data() {
    return {
      timer: null,
      userName: null,
      userId: null,
      qrCodeUrl: '',
      isTimeout: null
    }
  },
  created() {
    const userInfo = localStorage.getItem('userInfo')
    if (!['', null, undefined].includes(userInfo)) {
      this.userName = JSON.parse(userInfo).name
      this.userId = JSON.parse(userInfo).userId
      if (!['', null, undefined].includes(localStorage.getItem('token'))) {
        this.$router.push({ name: '首页' })
      }
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.qrCodeUrl !== '' && !this.isTimeout) {
        this.submit()
      }
    }, 3000)
    this.creatQrCode()
  },
  components: { detail },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    creatQrCode() {
      const _this = this
      this.$axios.get('/auth/pc/getQRCode').then(res => {
        if (res) {
          _this.qrCodeUrl = res.data.data
          _this.submit()
          new QRCode(this.$refs.qrCodeUrl, {
            text: _this.qrCodeUrl, // 需要转换为二维码的内容
            width: 180,
            height: 180,
            colorDark: '#000000',
            colorLight: '#ffffff',
          })
        }
      })
    },
    submit() {
      const _this = this
      const params = {
        qrCodeUrl: _this.qrCodeUrl
      }
      _this.$axios.post('/auth/pc/pollingQRCode', params).then(res => {
        if (res) {
          const data = res.data.data
          if (data.token === '1') {
            console.log('等待扫描')
          } else if (data.token === '2') {
            _this.isTimeout = true
          } else {
            this.$router.push({ name: '首页' })
            localStorage.setItem(
                'userInfo',
                JSON.stringify({
                  name: data.userName,
                  userId: data.userId
                })
            )
            localStorage.setItem('token', data.token)
            clearInterval(this.timer)
          }
        }
      })
    },
    refresh() {
      this.isTimeout = false
      const qrcode = document.getElementById('qrcode')
      const canvas = qrcode.getElementsByTagName('canvas')
      canvas[0].parentNode.removeChild(canvas[0])
      const img = qrcode.getElementsByTagName('img')
      img[0].parentNode.removeChild(img[0])
      this.creatQrCode()
    },
  }
}
</script>

<style lang="scss">
#particles-js {
  width: 100%;
  height: calc(100% - 400px);
  position: absolute;
}
.login {
  background-image: linear-gradient(-180deg, #F1A131 0%, #141e72 100%);
  /*background-image: url("../images/bg_login.png");*/
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;

  .login-container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    z-index: 999;

    .login-center {
      width: 600px;
      height: 300px;
      padding: 30px;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -180px 0 0 -380px;
    }

    .login-footer {
      position: absolute;
      bottom: 30px;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: 12px;
    }

    .login-a {
      text-decoration: none;
      color: #fff;
    }

    .logo-img {
      width: 300px;
      height: 300px;
    }
    .login-form {
      text-align: center;
      width: 300px;
      .page-login-qrcode {
        width: 190px;
        height: 190px;
        margin: 0 auto;
        background-color:rgba(255, 255, 255, 0.5);
      }
      .page-login-title {
        font-size: 30px;
        margin-top: 20px;
        font-weight: bold;
        color: #409eff;
      }

      .qrcode {
        padding: 5px;
        position: relative;
      }
    }
    .title {
      color: #F1A131;
    }
    .page-login-footer-title {
      font-size: 14px;
      margin-top: 10px;
      color: #555;
    }

    .faceCode-shade {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.97);
      padding: 0;
      margin: 0;
      color: #f56c6c;
      font-weight: 400;
    }

    .faceCode-title {
      margin-top: 60px;
      font-size: 16px;
    }

    .refresh {
      padding: 10px 30px;
      margin-top: 40px;
      color: #fff;
      background: #f56c6c;
      border-radius: 5px;
      font-size: 12px;
    }
  }
}
</style>
